<template>
<!--000043   fb360-->
  <v-app id="inspire">
    <!-- sidebar -->
    <v-navigation-drawer  color="#f4f5fa" v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app> <!--style="box-shadow: 0px 13px 5px 0px rgb(112 112 115 / 30%)!important;z-index: 9;"-->
      <!-- sidebar-menu -->
      <v-list class="pl-0 pr-4 pt-3 sidebar" dense> 
        <v-tooltip right open-delay="1000">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item to="/" link v-bind="attrs" v-on="on">
              <v-list-item-action class="mr-3">
                <v-icon>mdi-view-dashboard</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Pedidos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Lista de pedidos en curso</span>
        </v-tooltip>

        <v-tooltip right open-delay="1000">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item to="/ventas" link v-bind="attrs" v-on="on">
              <v-list-item-action class="mr-3">
                <v-icon> mdi-currency-usd</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Ventas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Lista de ventas anteriores</span>
        </v-tooltip>

        <v-tooltip right open-delay="1000">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item to="/productos" link v-bind="attrs" v-on="on">
              <v-list-item-action class="mr-3">
                <v-icon>mdi-taco</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Productos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Lista de productos</span>
        </v-tooltip>

        <v-tooltip right open-delay="1000">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item to="/colonias" link v-bind="attrs" v-on="on">
              <v-list-item-action class="mr-3">
                <v-icon> mdi-map-marker</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Colonias</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <span>Lista de colonias</span>
        </v-tooltip>
      </v-list>
      <template v-slot:append>
        <div style="color:#9ca3a5; font-size: 12px;" class="pa-2">
          Tacos el Güero 2021
        </div>
      </template>
    </v-navigation-drawer>
    <!-- header -->
    <v-app-bar class="elevation-0 px-0" :clipped-left="$vuetify.breakpoint.lgAndUp" app  color="#f4f5fa" dark><!-- box-shadow: 0px 0px 5px 0px rgb(112 112 115 / 30%)!important; -->
        <v-icon @click.stop="drawer = !drawer"  class="mr-5" color="grey"> mdi-menu</v-icon>
        <img style="height: 50px;" src="https://tacoselguero.mx/wp-content/uploads/2021/01/Captura-removebg-preview.png">
        <v-spacer></v-spacer>  
        <!-- search bar -->
        <v-text-field v-model="buscador" label="Buscador General" prepend-icon="mdi-magnify" rounded light class="hidden-sm-and-down mt-6"></v-text-field>
        <!-- home button -->
        <v-btn class="hidden-sm-and-down pa-0" icon to="/" link>
          <v-icon color="#707073">mdi-apps</v-icon>
        </v-btn>
        <!-- notification button -->
        <v-btn v-if="sucursalStatus=='inactivo'" class="py-0 mx-6" @click="update('activo')" link>
          Habilitar Sucursal
        </v-btn>
        <v-btn v-if="sucursalStatus=='activo'" class="py-0 mx-6" @click="update('inactivo')" link>
          Deshabilitar Sucursal
        </v-btn>
        <!-- account button -->
        <v-menu bottom left offset-y origin="top right" transition="scale-transition">
          <template v-slot:activator="{ attrs, on }">
            <v-btn class="ml-2 mr-1" text v-bind="attrs" v-on="on">
                <v-avatar size="32px">
                  <img src="https://tacoselguero.mx/wp-content/uploads/2021/05/default.png" >
                </v-avatar>
                <span class="hidden-sm-and-down" style="color:#707073; margin-left:10px; text-transform: initial; letter-spacing: 0px; font-size:16px; font-weight: 400;"> {{currentUser.name}} </span> <v-icon color="#707073">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <!-- dropdown account -->
          <v-list dense>
            <v-list-item-group color="primary">
              <!--v-list-item link to="/settings">
                <v-list-item-action>
                  <v-icon> mdi-cog</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Ajustes</v-list-item-title>
                </v-list-item-content>
              </v-list-item-->
              <v-list-item link @click="logout">
                <v-list-item-action>
                  <v-icon>mdi-power</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Cerrar Sesión</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
    </v-app-bar>
    <!-- content -->
    <v-main class="pa-0" style="background-color:#f4f5fa;">
      <v-container class="pa-0" fluid>
        <router-view v-bind:search="buscador"></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<!----------------------- Scripts ----------------------->
<script>
import axios from "axios";
  export default {
    name: 'AppContainer',
    data: () => ({
      drawer: false,
      buscador:'',
    }),
    methods:{
      logout(){
        this.$store.dispatch('currentUser/logoutUser')
      },
      update(status){
        this.sucursal.status = status
        axios.put("https://torstdio.site/api/v1/sucursal/actualizar",Object.assign(this.sucursal)).then(response=>{
            this.$store.dispatch('sucursal/getSucursales')
        })
      }
    },
    computed:{
      currentUser(){
        return this.$store.state.currentUser.user;
      },
      sucursal(){
          return this.$store.state.sucursal.sucursales.filter(sucursal=> sucursal.id == this.currentUser.sucursal)[0];
      },
      sucursalStatus(){
        return this.$store.state.sucursal.sucursales.filter(sucursal=> sucursal.id == this.currentUser.sucursal).map(sucursal=> sucursal.status)[0];
      }
    },
    created(){
      this.$store.dispatch('currentUser/getUser')
      this.$store.dispatch('sucursal/getSucursales')
    },
  }
</script>


<!-- Styles -->      
<style>
  .v-navigation-drawer__border{
    display:none!important;
  }
  .sidebar .v-list-item.v-list-item--link.theme--light:hover{
    background-color:#e9edf8!important; 
    border-radius: 0px 20px 20px 0px!important;
  }
  .sidebar .v-list-item.v-list-item--link.theme--light:hover i::before{
    color: #1f88fe!important;
  }
  .sidebar .v-list-item--active{
    background-color:#e9edf8!important; 
    border-radius: 0px 20px 20px 0px!important;
  }
  .sidebar .v-list-item--active i::before{
    color: #1f88fe!important;
  }
  .sidebar .theme--light.v-list-item--active:hover::before, .theme--light.v-list-item--active::before {
    opacity: 0!important;
  }
  .sidebar .theme--light.v-list-item:hover::before {
    opacity: 0;
  }
  .v-window__prev, .v-window__next {
    background: transparent!important;;
  }
  .v-carousel__controls{
    display:none!important;
  }
</style>