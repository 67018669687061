import Vue from 'vue'
import Vuex from 'vuex'

import currentUser from "./modules/currentUser"
import user from "./modules/user"
import producto from "./modules/producto"
import carrito from "./modules/carrito"
import sucursal from "./modules/sucursal"
import colonia from "./modules/colonia"
import pedido from "./modules/pedido"


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    currentUser,
    user,
    producto,
    carrito,
    sucursal,
    colonia,
    pedido
  }
})
