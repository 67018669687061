import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const routes = [
  {
    path: '/productos/',
    name: 'Productos',
    component: () => import('../components/productos.vue')
  },
  {
    path: '/colonias/',
    name: 'Colonias',
    component: () => import('../components/colonias.vue')
  },
  {
    path: '/',
    name: 'Pedidos',
    component: () => import('../components/pedidos.vue')
  },
  {
    path: '/ventas',
    name: 'Ventas',
    component: () => import('../components/ventas.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
