<template>
    <v-card :loading="loading">
        <template slot="progress">
        <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
        </template>
        <v-img height="100%" src="https://scontent.fntr6-3.fna.fbcdn.net/v/t1.6435-9/159388549_115363873939928_5145861702424859144_n.jpg?_nc_cat=106&ccb=1-3&_nc_sid=e3f864&_nc_ohc=fL3HbhMyfUoAX-bQazr&_nc_ht=scontent.fntr6-3.fna&oh=3a1af5c397d9cb48391174729fd76a32&oe=60AD619B"></v-img>
        <v-card style="border-radius:15px 15px 0px 0px; margin-top:-20px; background-color:#f2f2f2!important;" :loading="loading">
            <v-tabs background-color="#f2f2f2" v-model="tab" centered icons-and-text>
                <v-tabs-slider></v-tabs-slider>
                <v-tab>
                    Iniciar Sesión
                    <v-icon small>mdi-login-variant</v-icon>
                </v-tab>
                
                <v-tab-item>
                    <v-card flat>
                        <v-form class="px-4 mt-8">
                            <v-text-field 
                                :rules="[rules.required]"
                                @keydown.enter="login" 
                                label="Usuario | Correo Electrónico" 
                                name="login" 
                                v-model="user.email" 
                                outlined
                                rounded
                            ></v-text-field>
                            <v-text-field 
                                :rules="[rules.required]"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show1 ? 'text' : 'password'"
                                name="password"
                                label="Contraseña"
                                outlined
                                rounded
                                @click:append="show = !show"
                                @keydown.enter="login" v-model="user.password" 
                            ></v-text-field>
                        </v-form>
                        <div class="px-4">
                            <v-btn rounded block color="#093B99" dark type="submit" @click="login" class="btn btn-primary px-4"><strong>Acceder</strong></v-btn>
                        </div>
                    </v-card>
                </v-tab-item>

            </v-tabs>
        </v-card>
    </v-card>
</template>

<script>
import axios from "axios";
  export default {
    data: () => ({
        show: false,
        password: 'Password',
        loading: false,
        selection: 1,
        tab: null,
        rules: {
            required: value => !!value || 'Campo requerido.',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'E-mail inavlido.'
            },
        },
        user:{
            email: '',
            password: '',
        },
    }),

    methods:{
        login(){
            this.$store.dispatch('currentUser/loginUser', this.user);
        }, 
    },
  }
</script>

<style scoped>
.theme--light.v-tabs .v-tab--active:hover::before, .theme--light.v-tabs .v-tab--active::before {
    opacity: 0!important;
}
.v-tabs-slider{
    display: none!important;
}
.theme--light.v-tabs-items {
    border-radius: 15px 15px 0px 0px !important;
}
.v-tabs--centered {
    height: 75px!important;
}
</style>